
.juzhong{
    display: flex;
    justify-content: center;
    align-items: center;
}

.juyou{
    display: flex;
    justify-content: flex-end;
    align-items: center;

}
.juzuo{
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.height{
    height: 400px;
}

.img{
    width: 300px;
    height: 300px;
}

.img_content{
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}