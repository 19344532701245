.content{
    background: #f8f8f8;
    padding: 48px;
}
.item_title{
    text-align: left;
    padding-bottom: 8px;
    padding-top: 16px;
}

.item_content{
    display: flex;
    flex-wrap:wrap;
    /* justify-content: center; */
    align-items: center;
}

.item {
    background: rgb(242, 220, 180);
    padding: 8px;
    cursor: pointer;
    width: 150px;
    margin-right: 8px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.65);
}

.item:hover {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
        0 9px 28px 8px rgba(0, 0, 0, 0.05);
}