.div{
    display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;
    /* border: 1px solid  red; */
}
.left{
    white-space:nowrap;
    display: flex;
    justify-content: center;

}
.right{
    margin-top: 8px;
    color: #777;
    cursor: pointer;
    white-space:nowrap;
    display: flex;
    justify-content: center;

}

.admin{
    display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;

}